var site = site || {};

(function ($, Drupal) {
  Drupal.behaviors.ada = {
    attach: function () {
      var $tabbableElements = $();
      var $currentElementFocused = $();
      var index;
      var timer;
      var $selectBox = $();

      $(document).on('mousemove', _.debounce(function() {
        // If mouse movement is detected then remove ada class.
        if ($('html').hasClass('ada-enabled')) {
          $('html').removeClass('ada-enabled');
        }
      }, 200));

      $(window).on('keydown', function(event) {
        var key = event.key;
        if (!$('html').hasClass('ada-enabled') && ['Tab', 'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'].indexOf(key) > -1) {
          $('html').addClass('ada-enabled');
        }
      });

      $(document).off('keydown.ada').on('keydown.ada', function (e) {
        var key = e.key;

        if (['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp', 'Enter', 'Escape', ' '].indexOf(key) > -1) {
          $tabbableElements = $(':tabbable');
          $currentElementFocused = $(':focus');
          index = $tabbableElements.index($currentElementFocused);
          $selectBox = $('.selectBox-dropdown-menu:visible');

          switch (key) {
            case 'ArrowLeft':
            case 'ArrowUp':
              if ($selectBox.length > 0) {
                return;
              }
              if (index === 0) {
                // If the element is the first then focus the last element.
                focusElement(-1);
              } else if (!$currentElementFocused.attr('aria-haspopup')) {
                focusElement(index - 1);
              }
              break;
            case 'ArrowRight':
            case 'ArrowDown':
              if ($selectBox.length > 0) {
                return;
              }
              if (index === $tabbableElements.length - 1) {
                // If the element is the last then focus the first element.
                focusElement(0);
              } else if (!$currentElementFocused.attr('aria-haspopup')) {
                focusElement(index + 1);
              }
              break;
            case 'Enter':
            case ' ':
              if (timer) {
                clearTimeout(timer);
              }
              // Click will be fired if the element is diffent than a and button html tag.
              if (!$currentElementFocused.is('a') && !$currentElementFocused.is('button') && !$currentElementFocused.is(':checkbox')) {
                $currentElementFocused.click();
              }
              if ($currentElementFocused.hasClass('close-popup')) {
                // Focus previous element after a time in case it takes time to load
                focusTimer(index - 1);
              }
              // If the link/label has pop up then click to open
              if ($currentElementFocused.attr('aria-haspopup') && !$currentElementFocused.is('input')) {
                // Focus next element after a time in case it takes time to load
                focusTimer(index + 1);
              }
              break;

            case 'Escape':
              focusElement(index - 1);
              break;
          }
        }
      });

      function focusElement(index) {
        $tabbableElements.eq(index).focus();
      }

      function focusTimer(index) {
        timer = setTimeout(function () {
          // Update the var to get all the new tabbable elements.
          $tabbableElements = $(':tabbable');
          focusElement(index);
        }, 350);
      }

      // For retaining Tab Focus within Container given.
      site.restrict_navigation = function (element) {
        element.on('keydown', function (e) {
          var $tabbables = element.find(':tabbable');
          var $first = $tabbables.filter(':first');
          var $last = $tabbables.filter(':last');
          var $target = e.target;

          if (e.keyCode !== 9 || e.isDefaultPrevented()) {
            return;
          }
          if (($target === $last[0] || $target === element[0]) && !e.shiftKey) {
            $first.focus();
            e.preventDefault();
          } else if (($target === $first[0] || $target === element[0]) && e.shiftKey) {
            $last.focus();
            e.preventDefault();
          }
        });
      };

      site.getKeycode = function (e) {
        var keycode = e.keyCode ? e.keyCode : e.which;

        return keycode;
      };
    }
  };
})(jQuery, Drupal);

// Bind closing event for popups
$(document).bind('cbox_closed', function() {
  // Get the element which the focus should be returned to and the element which was deliberately hidden
  var $focusReturn = $('.js-return-focus');
  var $removeHidden = $('.js-remove-hidden');
  // Focus the element on closing
  $focusReturn.focus().removeClass('js-remove-hidden');
  // Unhide the next tabbable element
  if (typeof $removeHidden !== 'undefined') {
    $removeHidden.removeAttr('aria-hidden').removeClass('js-remove-hidden');
  }
});

$.curCSS = function(element, prop, val) {
  return $(element).css(prop, val);
};

site.applyTabsNavigation = function() {
  $('[role="tab"]').on('keydown', function(e) {
    var $this = $(this);
    var $prev = $this.prev();
    var $next = $this.next();
    var $target = [];
    var keyCode = site.getKeycode(e);
    var orientation = $this.data('role-orientation');
    var prevKey = orientation === 'vertical' ? 38 : 37; // Arrow Up : Arrow Left
    var nextKey = orientation === 'vertical' ? 40 : 39; // Arrow Down : Arrow Right

    switch (keyCode) {
      case prevKey:
        $target = $prev;
        break;
      case nextKey:
        $target = $next;
        break;
      case 9: // Tab
        $prev.attr('tabindex', '-1');
        $this.nextAll().attr('tabindex', '-1');
        $this.removeClass('custom-outline');
        break;
      // @todo check if space should be handled as "enter" as well
      case 13: // Enter
        if ($this.children('a').length === 1) {
          $this.children('a')[0].click();
        } else if ($this.children('span').length === 1) {
          $this.children('span').trigger('click');
        } else {
          $this.trigger('click');
        }
        break;
    }

    if ($target.length > 0) {
      $this.attr({
        'tabindex': '-1',
        'aria-selected': ''
      }).removeClass('custom-outline');
      $target.attr({
        'tabindex': '0',
        'aria-selected': true
      }).addClass('custom-outline').focus();
    }
  });
};

// To make jQuery selectBox accessible, used by script.js do not remove
function applySelectBoxAccessibility(context) {
  // Get all the select boxes within the context
  var selectBox = $('select', context);
  // Iterate each select box to make it accessible
  selectBox.each(function() {
    var control = $(this).next(),
        selectRequired = $(this).attr('aria-required'),
        selectLabel = $(this).attr('aria-label');
    var label = $(this).attr('id') + 'label';
    if (!control.hasClass('selectBox-dropdown')) {
      $(this).attr('role', 'combobox');
    } else {
      control.attr({
        'aria-haspopup': 'true',
        'aria-expanded': 'false',
        'role': 'combobox',
        'aria-required': selectRequired !== undefined ? selectRequired : 'false',
        'aria-describedby': label,
      });
      control.find('.selectBox-arrow').attr('aria-hidden', 'true');
      if (selectLabel !== undefined) {
        control.attr('aria-label', selectLabel);
        control.find('.selectBox-label').attr('id', label);
      }
      if ($(this).hasClass('js-sku-menu')) {
        $('.js-sku-menu').on('open.selectBox', function() {
          control.attr('aria-hidden', 'true');
          $('.js-sku-shade-name').removeAttr('aria-hidden');
        }).on('close.selectBox', function() {
          control.removeAttr('aria-hidden');
          $('.js-sku-shade-name').attr('aria-hidden', 'true');
        });
      }
      //Binding the required events
      $(this).off('focus open beforeclose').on({
        focus: function() {
          control = $(this).next();
        },
        open: function() {
          control.attr({
            'aria-expanded': 'true'
          });
        },
        beforeclose: function() {
          control.attr({
            'aria-expanded': 'false'
          });
        }
      });
    }
  });
}
